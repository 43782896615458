import type { ReactNode } from 'react'
import React, { useCallback, useEffect } from 'react'

import type { Variants } from 'framer-motion'
import Wrapper from './Wrapper'
import Overlay from './Overlay'
import Card from './Card'
import CloseButton from './CloseButton'
import useModal from '../hooks/useModal'

export type Props = {
  afterClose?: () => void
  afterOpen?: () => void
  alignment?: {
    x: 'left' | 'center' | 'right'
    y: 'top' | 'middle' | 'bottom' | 'center'
  }
  cardStyle?: string
  children?: ReactNode
  closeButton?: boolean
  closeOnOutsideClick?: boolean
  disabled?: boolean
  enqueuedToClose?: boolean // added by ModalProvider
  id?: string // added by ModalProvider
  variants?: {
    card?: Variants
    overlay?: Variants
  }
  className?: string
  cardClassName?: string
}

const Modal = ({
  afterClose,
  afterOpen,
  alignment = { x: 'center', y: 'center' },
  cardStyle,
  children,
  closeButton = true,
  closeOnOutsideClick,
  disabled,
  enqueuedToClose,
  id,
  variants,
  cardClassName,
  ...props
}: Props) => {
  const { closeModal, modalIsTop } = useModal()

  const handleClose = useCallback(() => {
    if (!disabled) {
      closeModal(id)

      if (afterClose) {
        afterClose()
      }
    }
  }, [afterClose, closeModal, disabled, id])

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()

        // @ts-ignore https://swoopme.atlassian.net/browse/ENG-60959
        if (!disabled && modalIsTop(id)) {
          handleClose()
        }
      }
    }

    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [disabled, handleClose, id, modalIsTop])

  useEffect(() => {
    if (afterOpen) {
      afterOpen()
    }
  }, [afterOpen])

  useEffect(() => {
    if (enqueuedToClose) {
      handleClose()
    }
  }, [enqueuedToClose, handleClose])

  return (
    <Wrapper alignment={alignment.x} {...props}>
      <Overlay
        onClick={closeOnOutsideClick ? handleClose : undefined}
        variants={variants?.overlay || undefined}
      />
      <Card
        alignment={alignment.y}
        cardStyle={cardStyle}
        className={cardClassName}
        variants={variants?.card || undefined}
      >
        {children}
        {closeButton && <CloseButton disabled={disabled} onClick={handleClose} />}
      </Card>
    </Wrapper>
  )
}

export default Modal
